import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.put("update_register_event/" +data.id_line, data);
  };


  return {
    Register,
  };
}
