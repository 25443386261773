<template>
  <!--begin::Input group-->
  <div class="row mb-6">
    <!--begin::Col-->
    <div class="col-lg-12 fv-row">
      <ckeditor
        style="height: 500px"
        :editor="editor"
        :config="editorConfig"
        v-model="content"
        :disabled="true"
      />

    </div>
    <!--end::Col-->
  </div>
  <!--end::Input group-->
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    dataEdit: {
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const content = ref(props.dataEdit);

    return {
      content,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [],
        shouldNotGroupWhenFull: true,
        fontSize: {
          options: [9, 11, 15, 17, 19, 21],
        },
        fontColor: {
          colors: [
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
            {
              color: "maroon",
              label: "maroon",
            },
            {
              color: "red",
              label: "red",
            },
            {
              color: "purple",
              label: "purple",
            },
            {
              color: "fuchsia",
              label: "fuchsia",
            },
            {
              color: "green",
              label: "green",
            },
            {
              color: "lime",
              label: "lime",
            },
            {
              color: "olive",
              label: "olive",
            },
            {
              color: "yellow",
              label: "yellow",
            },
            {
              color: "navy",
              label: "navy",
            },
            {
              color: "blue",
              label: "blue",
            },
            {
              color: "teal",
              label: "teal",
            },
            {
              color: "aqua",
              label: "aqua",
            },

            // ...
          ],
        },
        image: {
          resizeUnit: "%",
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              label: "Original size",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "resizeImage:25",
              value: "25",
              label: "25%",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "resizeImage:50",
              value: "50",
              label: "50%",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "resizeImage:75",
              value: "75",
              label: "75%",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "resizeImage:90",
              value: "90",
              label: "90%",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "resizeImage:100",
              value: "100",
              label: "100%",
              // Note: add the "icon" property if you're configuring a standalone button.
            },
          ],
        },
      },
    };
  },
};
</script>

<style></style>
