<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="card-body mt-2 white">
      <div class="body-text">
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Col-->
          <div class="col-12 fv-row">
            <Field
              type="text"
              name="search"
              class="form-control form-control-solid"
              placeholder="ค้นหา"
              v-model="search"
            />
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
      </div>
      <div class="body-text" v-if="dataSearch.length > 0">
        <template v-for="event in dataSearch" :key="event">
            <div class="card mt-3">
              <img
                style="width: 100%"
                :src="`${event.image}`"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <p class="travel-card-name">
                  {{ event.name }}
                </p>
                <p class="travel-card-text">วันที่ {{ dateThai(event.date_start) }}  ถึง  {{ dateThai(event.date_end) }}</p>
                <p class="travel-card-location">
                  <svg
                    style="width: 0.75em"
                    class="svg-inline--fa fa-map-marker-alt fa-w-12"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="map-marker-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                    ></path>
                  </svg>
                 ต.{{ event.district }} อ.{{ event.amphoe }} 
                 <button type="button" @click="getTo(event.id)" style="float: right;" class="btn btn-outline-info">รายละเอียด</button>
                </p>
              </div>
            </div>
         
        </template>
      </div>
      <div class="body-text" v-else>
        <template>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed, inject } from "vue";
import { Field } from "vee-validate";
import useEvents from "@/service/api/event";
import moment from "moment";
export default defineComponent({
  name: "Procedures-All",
  components: {
    Field,
  },
  setup() {
    const { getEvent_history } = useEvents();
    const events = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const search = ref("");
    const router = inject("router");

    onMounted(() => {
      getEvent_history().then((res) => {
        events.value = res.data.data;
        isLoading.value = false;
      });
    });

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year);
    }

    const getTo = (id) => {

      router.push({
        name: "EventShow",
        params: { id: id }
      });
    };

    const dataSearch = computed(() => {
      return events.value.filter((item) => {
        return item.name.toLowerCase().includes(search.value.toLowerCase());
      });
    });

    return {
      events,
      isLoading,
      color,
      search,
      dataSearch,
      getTo,
      dateThai
    };
  },
});
</script>
<style scoped>
.travel-header {
  padding: 8px;
}
.travel-header-name {
  margin-left: -10px;
  color: white;
  font-size: 16px;
  background-color: black;
  padding: 0px 13px;
  border-radius: 0px;
  width: 150px;
}

.travel-card-name {
  font-size: 15px;
  margin: 0px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}
.card {
  height: auto;
  width: 100%;
  box-shadow: none;
}
.card-image {
  background-size: cover;
  background-clip: border-box;
}

.card-body {
  padding: 10px;
}
.travel-card-text {
  margin: 0px;
  font-size: 13px;
  line-height: 1.5em;
  height: 2em;
  overflow: hidden;
}
</style>
