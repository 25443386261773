<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px; padding-top:5px" alt="..." />
          <div class="t1" style="margin: 5px">ลงทะเบียน</div>
        </div>
        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >วันเดือนปีเกิด</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row">
                    <div class="col-3">
                      <Field
                        as="select"
                        name="day"
                        class="form-control form-control-solid"
                      >
                        <option value="" disabled selected>วัน</option>
                        <option v-for="index in 31" :key="index" :value="index">
                          {{ index }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="day" />
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <Field
                        as="select"
                        name="month"
                        class="form-control form-control-solid"
                      >
                        <option value="" disabled selected>เดือน</option>
                        <option
                          v-for="n in monthNamesThai"
                          :key="n.id"
                          :value="n.id"
                        >
                          {{ n.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="month" />
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <Field
                        as="select"
                        name="year"
                        class="form-control form-control-solid"
                      >
                        <option value="" disabled selected>ปี (พ.ศ.)</option>
                        <option
                          v-for="(n, index) in setRange()"
                          :key="index"
                          :value="n"
                        >
                          {{ n }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="year" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ลงทะเบียน</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
import liff from "@line/liff";
import useRegister from "@/service/api/register";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    const { Register } = useRegister();
    const { Sconfirm, SconfirmNc } = useSweetalert();

    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    const register = Yup.object().shape({
      day: Yup.string().required("กรุณาเลือก วัน"),
      month: Yup.string().required("กรุณาเลือก เดือน"),
      year: Yup.string().required("กรุณาเลือก ปี"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      Sconfirm("ยืนยัน การลงทะเบียน", "question")
        .then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            values.birthday = setday(values.day, values.month, values.year);
            values.id_line = store.getters.getAccessToken.accesstoken;
            await Register(values).then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Home" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
          }
        });
    };

    const setRange = () => {
      let value = [];
      let i;
      for (i = 2565; i >= 2400; i--) {
        value.push(i);
      }
      return value;
    };

    const setday = (day, month, year) => {
      let y = year - 543;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    return {
      onSubmitRegister,
      register,
      monthNamesThai,
      setRange,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped></style>
