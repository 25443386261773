<template>
  <div class="card-header text-end">
    <img src="@/assets/bg.png" class="image-bg" alt="image" />
    <div>
      <i @click="setProfile" class="bi bi-gear icon"></i>
    </div>

  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const AppName = process.env.VUE_APP_NAME;
    const setProfile = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });
    };

    return {
      setProfile,
      AppName
      
    };
  },
});
</script>

<style scoped></style>
