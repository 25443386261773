<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow">
          <Header></Header>

          <div class="text-start mn-profile" v-if="events">
            <span class="text-root mini-h"> {{ events.name }} </span>
          </div>

          <div class="traver-body" v-if="events">
            <div class="mb-3">
              <div class="card-body white">
                <div class="history">
                  <div class="body">
                    <span class="name">ช่วงเวลาการจัดงาน : </span>
                    {{ dateThai(events.date_start) }} -
                    {{ dateThai(events.date_end) }}<br />
                    <span class="name">สถานที่จัดงาน : </span>
                    ต.{{ events.district }} อ.{{ events.amphoe }} <br />
                    <span class="name">จำนวนผู้ร่วมกิจกรรม : </span>
                    {{ events.amount_type }}

                    <span v-if="events.amount_type == 'จำกัด'">
                      : {{ events.amount }} คน</span
                    >
                    <br />
                    <span class="name">จำนวนผู้ร่วมกิจกรรมแล้ว : </span>
                    {{ register_event_count }} คน <br />

                    <template v-if="events.event_condition === 'มี'">
                      <span class="name">เงื่อนไขการลงทะเบียน</span>
                      <br />
                      <span class="name">เพศ : </span>
                      {{ events.condition_gender }} <br />
                      <template v-if="events.condition_age === 'จำกัด'">
                        <span class="name">อายุ : </span>
                        {{ events.condition_age_start }} -
                        {{ events.condition_age_end }} ปี<br />
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-10 card">
              <BaseContentShow :dataEdit="events.content" :disabled="true" />
            </div>

            <div class="mb-3 mt-3" v-if="events">
              <div class="card-body white">
                <div v-if="count === ''">
                  <button
                    v-if="register_event === 0"
                    style="width: 100%"
                    @click="register"
                    class="button btn btn-outline-success"
                  >
                    ลงทะเบียนเข้าร่วมกิจกรรม
                  </button>
                  <button
                    disabled
                    v-if="register_event === 1"
                    style="width: 100%"
                    class="button btn btn-success"
                  >
                    ลงทะเบียนเข้าร่วมแล้ว
                  </button>
                </div>
                <div v-else>
                  <div v-if="count > 0">
                    <button
                      v-if="register_event === 0"
                      style="width: 100%"
                      class="button btn btn-outline-success"
                      @click="register"
                    >
                      ลงทะเบียนเข้าร่วมกิจกรรม
                    </button>
                    <button
                      disabled
                      v-if="register_event === 1"
                      style="width: 100%"
                      class="button btn btn-success"
                    >
                      ลงทะเบียนเข้าร่วมแล้ว
                    </button>
                  </div>
                  <div v-else>
                    <button
                      disabled
                      style="width: 100%"
                      class="button btn btn-danger"
                    >
                      ลงทะเบียนเต็มแล้ว
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-47"></div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button @click="back" class="button btn-back">ย้อนกลับ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import useEvents from "@/service/api/event";
import Header from "@/components/Header";
import { useRoute } from "vue-router";
import { event } from "vue-gtag";
import useSweetalert from "@/service/sweetalert2";
import BaseContentShow from "@/components/BaseContentShow.vue";
import moment from "moment";
export default defineComponent({
  name: "Profile",
  components: {
    Header,
    BaseContentShow,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const events = ref();
    const register_event = ref(0);
    const register_event_count = ref(0);
    const count = ref("");
    const { Sconfirm, SconfirmNc } = useSweetalert();

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { getEvent, Register_Event } = useEvents();

    const route = useRoute();
    const id = ref(route.params.id);

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year);
    }
    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      if (!id.value) {
        return router.push({ name: "Dashboard" });
      }
      getEvent(id.value)
        .then((response) => {
          events.value = response.data.data;
          register_event.value = response.data.register_event;
          register_event_count.value = response.data.register_event_count;

          if (response.data.data.amount_type === "จำกัด") {
            count.value =
              response.data.data.amount - response.data.register_event_count;
          }
          isLoading.value = false;

          event("click", {
            event_category: "event",
            event_label: response.data.data.name,
            value: store.getters.getProfireLine.userId,
          });
        })
        .catch(() => {
          isLoading.value = false;
        });
    });

    const register = () => {
      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          const data = {
            id: id.value,
          };

          await Register_Event(data)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Dashboard" });
                });
              } else if (result.data.status === "maxlimit") {
                SconfirmNc("ลงทะเบียนเต็มแล้ว", "error", "ตกลง").then(() => {
                  router.push({ name: "Dashboard" });
                });
              } else {
                SconfirmNc("ไม่เข้าเงื่อนไขการลงทะเบียน", "error", "ตกลง");
              }
            })
            .catch(() => {
              SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
            });
        }
      });
    };

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      events,
      isLoading,
      color,
      back,
      dateThai,
      register_event,
      count,
      register,
      register_event_count,
    };
  },
});
</script>
<style scoped>
.travel-header {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.travel-header-name {
  margin-left: -10px;
  color: white;
  font-size: 16px;
  background-color: black;
  padding: 0px 13px;
  border-radius: 0px;
  width: 150px;
}
.appeal-image-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.mn {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  background-color: white;
}
.travel-card-name {
  font-size: 15px;
  margin: 0px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}
.name {
  font-weight: bold;
}
.card {
  height: auto;
  width: 100%;
  box-shadow: none;
}
.card-image {
  background-size: cover;
  background-clip: border-box;
}

.card-body {
  padding: 10px;
}
.travel-card-text {
  margin: 0px;
  font-size: 13px;
  line-height: 1.5em;
  height: 6em;
  overflow: hidden;
  text-indent: 2em;
}
.f18 {
  font-size: 18px;
  color: var(--color);
}
</style>
