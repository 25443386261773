import ApiService from "@/service/ApiService";

export default function useEvents() {


  const getProcedures = async () => {
    return await ApiService.get("procedures");
  };

  const getEvent_all = async () => {
    return await ApiService.get("event_all");
  };

  const getEvent_history = async () => {
    return await ApiService.get("event_history");
  };


  

  const getEvent = async (id) => {
    return await ApiService.get("event/" + id);
  };


  const Register_Event = async (data) => {
    return await ApiService.post("event",data);
  };





  return {
    getProcedures,
    getEvent_all,
    getEvent,
    Register_Event,
    getEvent_history
  };
}
